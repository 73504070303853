<template>
  <ul>
    <!-- sale item -->
    <li v-for="item in items" class="minicart-item sale-mini-cart-item" :key="item.key">
      <div class="minicart-thumb">
        <a :href="'/items/' + item.item_key">
          <img :src="item.image" :alt="item.title">
        </a>
      </div>
      <div class="minicart-content">
        <h3 class="product-name">
        <a :href="'/items/' + item.item_key">{{item.title}}</a>
        </h3>
        <p>
          <span class="cart-quantity">{{item.qty}} <strong>&times;</strong></span>
          <span class="cart-price">{{item.price}}元</span>
        </p>
      </div>
      <button @click="rm_item(item)" class="minicart-remove"><i class="lnr lnr-cross"></i></button>
    </li>
    <!-- gift -->
    <li v-for="gift in gifts" class="minicart-item sale-mini-cart-item" :key="gift.key">
      <div class="minicart-thumb">
        <img :src="gift.image" :alt="gift.title">
      </div>
      <div class="minicart-content">
        <h3 class="product-name">
          {{gift.title}} - (加價購商品)
        </h3>
        <p>
          <span class="cart-quantity">{{gift.qty}} <strong>&times;</strong></span>
          <span class="cart-price">{{gift.price}}元</span>
        </p>
      </div>
      <button @click="rm_gift(gift)" class="minicart-remove"><i class="lnr lnr-cross"></i></button>
    </li>
  </ul>
</template>

<script>
export default {
  computed: {
    items: function() {
      return this.$store.state.items_in_sale_cart;
    },
    gifts: function() {
      return this.$store.state.items_in_gift_cart;
    }
  },
  methods: {
    rm_item: function(item) {
      var currentItems = this.items;
      var self = this;
      this.$store.dispatch('rm_item_from_sale_cart', item).then(function(){
        // 判斷minicart-item的數量決定是否關閉
        if (currentItems.length - 1 === 0 || $(".sale-mini-cart-item").length <= 1) {
          // 清除所有加價購商品
          self.$store.dispatch("empty_gifts");
          $("body").removeClass('fix');
          $(".sale-cart-inner").removeClass('show');
        }
      });
    },
    rm_gift: function(gift) {
      this.$store.dispatch('rm_item_from_gift_cart', gift).then(function(){
        if ($(".sale-mini-cart-item").length <= 1) {
          $("body").removeClass('fix');
          $(".sale-cart-inner").removeClass('show');
        }
      });
    }
  }
}
</script>