<template>
  <div>
    <div class="mt5 flex flex_center">
      <img :src="preview_card_path">
    </div>
    <div class="mt20 tac">
      <a href="#" @click="$emit('goTo', -1)" class="btn btn__bg">上一步</a>&nbsp;&nbsp;
      <a href="#" class="btn btn__bg" @click.prevent="confirm">
        <i class="far fa-clipboard"></i> 確定
      </a>
    </div>
  </div>
</template>

<script>
export default {
  props: ["preview_card_path"],
  data() {
    return {

    }
  },
  methods: {
    cancel() {
      $("#independent-page-modal").modal('hide');
    },
    confirm() {
      // 與取消同，然後跳出視窗詢問
      this.cancel();
      this.$emit('createCard');
    }
  }
}

</script>