<template>
  <ValidationObserver class="posr" v-slot="{ handleSubmit }">
    <form class="form-horizontal mt20" @submit.prevent="handleSubmit(setStepAndGo)">
      <!-- 公司品牌logo -->
      <div v-if="showColumn('logo')" class="flex mt10">
        <div class="fx1 posr">
          <input type="file" class="form-control" ref="logo" @change.prevent="uploadFile('logo')" />
          <div class="posa" style="right: 10px; top: 6px">
            <label v-show="!arrangedData.logo">個人圖像/品牌LOGO</label>
            <img
              v-show="arrangedData.logo"
              :src="arrangedData.logo"
              style="object-fit: cover; width: 25px; height: 25px"
            />
          </div>
        </div>
      </div>
      <!-- 附加圖片 -->
      <div v-if="showColumn('custom_image')" class="flex mt10">
        <div class="fx1 posr">
          <input
            type="file"
            class="form-control"
            ref="custom_image"
            @change.prevent="uploadFile('custom_image')"
          />
          <div class="posa" style="right: 10px; top: 6px">
            <label v-show="!arrangedData.custom_image">可上傳廣告文宣</label>
            <img
              v-show="arrangedData.custom_image"
              :src="arrangedData.custom_image"
              style="object-fit: cover; width: 25px; height: 25px"
            />
          </div>
        </div>
      </div>
      <!-- 聯絡人姓名 -->
      <div v-if="showColumn('salesman_name')" class="flex mt10">
        <div class="fx1">
          <ValidationProvider
            tag="div"
            class="validation posr"
            rules="required"
            v-slot="{ errors }"
          >
            <input
              type="text"
              v-model="arrangedData.salesman_name"
              class="form-control"
              placeholder="主標(例如公司名/姓名，建議10個字內)..."
            />
            <div class="error">{{ errors[0] }}</div>
          </ValidationProvider>
        </div>
      </div>
      <!-- 手機號碼 -->
      <div v-if="showColumn('cell')" class="flex mt10">
        <div class="fx1">
          <ValidationProvider
            tag="div"
            class="validation posr"
            rules="required"
            v-slot="{ errors }"
          >
            <input
              type="text"
              v-model="arrangedData.cell"
              class="form-control"
              placeholder="次標(例如手機、電話或賴ID，建議16字內)..."
            />
            <div class="error">{{ errors[0] }}</div>
          </ValidationProvider>
        </div>
      </div>
      <!-- 店址 -->
      <div v-if="showColumn('shop_address')" class="flex mt10">
        <div class="fx1">
          <ValidationProvider
            tag="div"
            class="validation posr"
            rules="required"
            v-slot="{ errors }"
          >
            <input
              type="text"
              v-model="arrangedData.shop_address"
              class="form-control"
              placeholder="小標(店址或你想填寫的資訊，建議26個字內)..."
            />
            <div class="error">{{ errors[0] }}</div>
          </ValidationProvider>
        </div>
      </div>
      <!-- 其他提醒，除layout0外其餘都顯示 -->
      <div v-if="!(layout === 'layout0')" class="flex mt10">
        <div class="flex fx1" style="justify-content: space-between">
          <p>*此修改僅供本次P圖使用</p>
          <!-- 更新填寫內容到會員資訊 -->
          <label
            ><input
              @change.prevent="update_user_data = !update_user_data"
              type="checkbox"
              :checked="update_user_data"
            />
            更新P圖會員資訊</label
          >
        </div>
      </div>
      <!-- layout0說明圖 -->
      <div v-if="layout === 'layout0'" class="flex mt10">
        <div class="flex fx1">
          <img :src="layout0_position" />
        </div>
      </div>
      <!-- layout0的位置選擇 -->
      <div v-if="layout === 'layout0'" class="flex mt10">
        <div class="flex fx1" style="justify-content: space-between">
          <div
            v-for="(postion, index) in customImagePositions"
            :key="`position${index}`"
            class="fx1 flex flex_center"
          >
            <label>
              <input type="radio" :value="postion.value" v-model="custom_image_position" />
              {{ postion.label }}
            </label>
          </div>
        </div>
      </div>
      <!-- 使用廣告文宣 -->
      <div class="flex mt10">
        <div class="fx1">
          <label>
            <input type="checkbox" v-model="use_custom_image" />
            &nbsp;使用廣告文宣
          </label>
        </div>
        <div v-if="layout === 'layout0'" class="fx1" style="text-align: right">
          <label
            ><input
              @change.prevent="update_user_data = !update_user_data"
              type="checkbox"
              :checked="update_user_data"
            />
            更新P圖會員資訊
          </label>
        </div>
      </div>
      <div v-if="layout === 'layout0'">
        <p class="red">*請選擇上傳圖片(您的圖片)放置位置</p>
      </div>
      <!-- 附加圖片 -->
      <div v-if="layout === 'layout0'" class="flex mt10">
        <div class="fx1 posr">
          <input
            type="file"
            class="form-control"
            ref="custom_image"
            @change.prevent="uploadFile('custom_image')"
          />
          <div class="posa" style="right: 10px; top: 6px">
            <label v-show="!arrangedData.custom_image">可上傳廣告文宣</label>
            <img
              v-show="arrangedData.custom_image"
              :src="arrangedData.custom_image"
              style="object-fit: cover; width: 25px; height: 25px"
            />
          </div>
        </div>
      </div>
      <!-- submit -->
      <div class="flex mt10">
        <div class="fx1 flex flex_center">
          <!-- cancel -->
          <a href="#" @click="$emit('goTo', -1)" class="btn btn__bg">上一步</a>&nbsp;&nbsp;
          <button type="submit" class="btn btn__bg" :disabled="generating">
            {{ generating ? "處理中..." : "下一步" }}
          </button>
        </div>
      </div>
    </form>
  </ValidationObserver>
</template>

<script>
import { extend } from "vee-validate";
import { required } from "vee-validate/dist/rules";

extend("required", {
  ...required,
  message: "必填",
});

// custom_image位置選項

export default {
  props: ["user_data", "layout", "layout0_position"],
  data() {
    return {
      arrangedData: { ...this.user_data },
      customImagePositions: [
        { value: "top", label: "上 方" },
        { value: "bottom", label: "下 方" },
        { value: "right", label: "右 邊" },
        { value: "left", label: "左 邊" },
      ],
      update_user_data: false,
      generating: false,
      custom_image_position: "top",
      use_custom_image: false,
    };
  },
  watch: {},
  methods: {
    setStepAndGo() {
      // 將資料轉到wiward_steps
      const { arrangedData, update_user_data, custom_image_position, use_custom_image } = this;
      this.generating = true;
      setTimeout(() => {
        this.generating = false;
      }, 5000);
      // 如果不使用custom_image就把arrangeData.custom_image清空
      if (!this.use_custom_image) {
        arrangedData.custom_image = null;
      }
      // 將資料轉到wiward_steps
      this.$emit(
        "arrangeData",
        { ...arrangedData, update_user_data, custom_image_position, use_custom_image },
        2
      );
      this.$emit("setPreview");
    },
    uploadFile(fileType) {
      const file = this.$refs[fileType].files[0];
      const self = this;
      let width;
      switch (fileType) {
        case "logo":
          width = 400;
          break;
        case "custom_image":
          width = 1273;
          break;
      }
      // 無檔案就不執行
      if (!file) return;
      const reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onload = function (event) {
        const imgElement = document.createElement("img");
        imgElement.src = event.target.result;
        imgElement.onload = function (evt) {
          const canvas = document.createElement("canvas");
          const MAX_WIDTH = width;
          const ratio = MAX_WIDTH / evt.target.width;
          canvas.width = MAX_WIDTH;
          canvas.height = evt.target.height * ratio;
          const ctx = canvas.getContext("2d");
          ctx.drawImage(evt.target, 0, 0, canvas.width, canvas.height);
          const srcEncoded = ctx.canvas.toDataURL("image/jpeg", 0.8);
          self.arrangedData[fileType] = srcEncoded;
        };
      };
    },
    showColumn(column) {
      // 20220804 不用判斷，只剩三個欄位
      // 20230220 改成除了layout0顯示custom_image外，其他都顯示
      // 20230223 layout0全部都不顯示，但custom_image要顯示，而且放在特別的位置
      if (this.layout === "layout0") {
        return false;
      } else {
        return true;
      }
    },
    toggleUseCustomImage() {
      this.use_custom_image = !this.use_custom_image;
    },
  },
};
</script>
