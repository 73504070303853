<template>
    <div>
      <div v-if="show_specification" class="quantity-cart-box d-flex align-items-center">
        <h5>規格:</h5>
        <div>
          <select v-model="selected_item_specification" @change="resetQty" class="nice-select">
            <option v-for="is in item_specifications" :value="is">{{is.title}}</option>
          </select>
        </div>
      </div>
      <div v-if="show_specification" class="quantity-cart-box d-flex align-items-center">
        <h5>庫存:</h5>
        <div>{{selected_item_specification.qty}} PCS</div>
      </div>
      <div class="quantity-cart-box d-flex align-items-center">
        <h5>數量:</h5>
        <div class="quantity">
          <div class="pro-qty">
            <span @click="minus" class="dec qtybtn">-</span>
            <input type="number" v-model="qty" :max="max_qty" step="1" min="1">
            <span @click="plus" class="inc qtybtn">+</span>
          </div>
        </div>
        <div class="action_link">
          <button type="button" class="btn btn-cart2" @click="addTo">加入購物車</button>
        </div>
      </div>
      <div class="quantity-cart-box d-flex align-items-center">
        <h5>我想要客製化或大量購買此商品 <a rel="nofollow" href="#" @click.prevent="addToQuery">加入詢價車</a></h5>
      </div>
    </div>
</template>

<script>
export default {
  props: ['max_qty', 'item_key', 'item_specifications', 'show_specification'],
  data: function() {
    return {
      selected_item_specification: this.item_specifications[0],
      qty: 1
    }
  },
  methods: {
    resetQty: function() {
      // 變換規格後數量需重設為1
      this.qty = 1;
    },
    addTo: function() {
      this.$store.dispatch('addToSaleCart', {key: this.selected_item_specification.key, qty: this.qty});
      sweetAlert({
        title: "",
        text: "成功加入購物車!",
        type: "success"
      });
    },
    plus: function() {
      var tempQty = this.qty + 1;
      if (tempQty <= this.selected_item_specification.qty && tempQty <= this.max_qty) {
        this.qty = this.qty + 1;
      }
    },
    minus: function() {
      this.qty = this.qty - 1
      if (this.qty <= 0) {
        this.qty = 1;
      }
    },
    addToQuery: function() {
      // 取得當前的key與qty
      // 加入購物車
      this.$store.dispatch('addToQueryCart', {key: this.item_key});
      sweetAlert({
        title: "",
        text: "成功加入詢價車!",
        type: "success"
      });
    }
  }
}
</script>