<template>
  <li v-if='$store.state.items_in_query_cart.length > 0' @click='showSideCart'><a id='query-cart-btn' href='#'><i class='lnr lnr-briefcase'></i> <div class='notification'>{{$store.state.items_in_query_cart.length}}</div></a></li>
</template>

<script>
export default {
  methods: {
    showSideCart: function() {
      $("body").addClass('fix');
      $(".query-cart-inner").addClass('show');
    }
  }
}
</script>