<template>
  <tbody>
    <tr v-for="(item, index) in items" :key="`item_${index}`">
      <td class="pro-thumbnail">
        <a :href="'/items/' + item.item_key"><img class="img-fluid" :src="item.image"/></a>
      </td>
      <td class="pro-title">
        <a :href="'/items/' + item.item_key">
          <span v-if="item.free_shipping === 'yes_free'" class="red">[免運]</span>
          {{ item.title }}
        </a>
      </td>
      <td class="pro-price">
        <span>{{ item.price }}元</span>
      </td>
      <td class="pro-quantity">
        <div class="pro-qty">
          <span @click="minus(item)" class="dec qtybtn">-</span>
          <input
            :ref="'input_' + item.key"
            type="number"
            :value="item.qty"
            :max="item.max_qty"
            step="1"
            min="1"
            @keyup="updateQty(item)"
          />
          <span @click="plus(item)" class="inc qtybtn">+</span>
        </div>
      </td>
      <td class="pro-subtotal">
        <span>{{ item.qty * item.price }}元</span>
      </td>
      <td class="pro-remove">
        <a href="#" @click="rm_item(item)"><i class="far fa-trash-alt"></i></a>
      </td>
    </tr>
    <!-- 加價購商品 -->
    <tr v-for="(item, index) in gifts" :key="`gift_${index}`">
      <td class="pro-thumbnail">
        <img class="img-fluid" :src="item.image" />
      </td>
      <td class="pro-title">{{ item.title }} - 加價購商品</td>
      <td class="pro-price">
        <span>{{ item.price }}元</span>
      </td>
      <td class="pro-quantity">
        <div class="pro-qty">
          <span @click="minusGift(item)" class="dec qtybtn">-</span>
          <input
            :ref="'input_' + item.key"
            type="number"
            :value="item.qty"
            :max="item.max_qty"
            step="1"
            min="1"
            @keyup="updateQty(item)"
          />
          <span @click="plusGift(item)" class="inc qtybtn">+</span>
        </div>
      </td>
      <td class="pro-subtotal">
        <span>{{ item.qty * item.price }}元</span>
      </td>
      <td class="pro-remove">
        <a href="#" @click="rm_gift(item)"><i class="far fa-trash-alt"></i></a>
      </td>
    </tr>
    <!-- 運費/總計 -->
    <tr>
      <td colspan="4" class="tar">商品總計:</td>
      <td>{{ cart_amount }} 元</td>
      <td></td>
    </tr>
    <tr>
      <td colspan="4" class="tar">運費:</td>
      <td>{{ freight }} 元</td>
      <td></td>
    </tr>
    <tr>
      <td colspan="4" :class="mobile_mode ? '' : 'tar'">
        <div :class="mobile_mode ? 'float-left' : ''">
          折價劵:
          <input
            v-model="coupon_no"
            type="text"
            class="form-control dib"
            style="width:190px"
            placeholder="如有折價券請這邊輸入號碼..."
          />
          <button class="btn btn__bg" @click="submitCoupon">送出</button>
        </div>
      </td>
      <td>{{ coupon.discount > 0 ? "-" : "" }} {{ coupon.discount }} 元</td>
      <td></td>
    </tr>
    <tr>
      <td colspan="4" class="tar">總金額:</td>
      <td>{{ freight + cart_amount - coupon.discount }} 元</td>
      <td></td>
    </tr>
  </tbody>
</template>

<script>
export default {
  props: ["mobile_mode"],
  data: function() {
    return {
      coupon_no: "",
    };
  },
  computed: {
    items: function() {
      return this.$store.state.items_in_sale_cart;
    },
    gifts: function() {
      return this.$store.state.items_in_gift_cart;
    },
    freight: function() {
      return this.$store.state.freight;
    },
    cart_amount: function() {
      return this.$store.state.cart_amount;
    },
    coupon: function() {
      return this.$store.state.coupon;
    },
  },
  methods: {
    rm_item: function(item) {
      this.$store.dispatch("rm_item_from_sale_cart", item);
    },
    rm_gift: function(gift) {
      this.$store.dispatch("rm_item_from_gift_cart", gift);
    },
    plus: function(item) {
      if (item.qty + 1 <= item.max_qty) {
        this.$store.dispatch("addToSaleCart", { key: item.key, qty: item.qty + 1 });
      } else {
        sweetAlert({
          title: "",
          text: "已超過數量上限！",
          type: "warning",
        });
      }
    },
    minus: function(item) {
      if (item.qty - 1 > 0) {
        this.$store.dispatch("addToSaleCart", { key: item.key, qty: item.qty - 1 });
      } else {
        sweetAlert({
          title: "",
          text: "數量不可小於1",
          type: "warning",
        });
      }
    },
    plusGift: function(item) {
      if (item.qty + 1 <= item.max_qty) {
        this.$store.dispatch("addToGiftCart", {
          key: item.key,
          qty: item.qty + 1,
          no_message: true,
        });
      } else {
        sweetAlert({
          title: "",
          text: "已超過數量上限！",
          type: "warning",
        });
      }
    },
    minusGift: function(item) {
      if (item.qty - 1 > 0) {
        this.$store.dispatch("addToGiftCart", {
          key: item.key,
          qty: item.qty - 1,
          no_message: true,
        });
      } else {
        sweetAlert({
          title: "",
          text: "數量不可小於1",
          type: "warning",
        });
      }
    },
    updateQty: function(item) {
      // 不允許直接修改
      // var value = parseInt(this.$refs['input_' + item.key][0].value);
      // this.$store.dispatch('addToSaleCart', {key: item.key, qty: value});
      sweetAlert({
        title: "",
        text: "請使用加號或減號修改數量！",
        type: "warning",
      });
    },
    submitCoupon: function() {
      var coupon_no = this.coupon_no;
      if (coupon_no.length > 0) {
        this.$store.dispatch("coupon_check", { coupon_no: coupon_no });
      } else {
        sweetAlert({
          type: "warning",
          title: "請輸入折價劵號碼!",
        });
      }
    },
  },
  watch: {
    items: function(newVal) {
      if (!newVal[0]) {
        sweetAlert({
          title: "",
          text: "購物車被清空！",
          type: "warning",
        }).then(function() {
          window.location.href = "/";
        });
      }
    },
  },
};
</script>
