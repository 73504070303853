import Vue from "vue/dist/vue.esm";
// 套件
import { ValidationProvider, ValidationObserver } from "vee-validate";
// store
import store from "./store";
// components
import AddToJqueryCartBtn from "./components/add_to_query_cart_btn.vue";
import AddToQueryCartForm from "./components/add_to_query_cart_form.vue";
import AddToSaleCartBtn from "./components/add_to_query_cart_btn.vue";
import AddToSaleCartForm from "./components/add_to_sale_cart_form.vue";
import CartAmountData from "./components/cart_amount_data.vue";
import MobileQueryCartIcon from "./components/mobile_query_cart_icon.vue";
import MobileSaleCartIcon from "./components/mobile_sale_cart_icon.vue";
import QueryCartIcon from "./components/query_cart_icon.vue";
import QueryCartItemRow from "./components/query_cart_item_row.vue";
import QueryMiniItem from "./components/query_mini_item.vue";
import SaleCartIcon from "./components/sale_cart_icon.vue";
import SaleCartItemRow from "./components/sale_cart_item_row.vue";
import SaleMiniItem from "./components/sale_mini_item.vue";
import CheckoutBtn from "./components/checkout_btn.vue";
import AddGiftForm from "./components/add_gift_form.vue";
import CartGift from "./components/cart_gifts.vue";
import NewStep from "./components/new_step.vue";
// import CardCanvas from './components/card_canvas.vue';
// import CardCanvas2 from './components/card_canvas2.vue';
// import CreateCardBtn from './components/create_card_btn.vue';
import Steps from "./components/steps.vue";

// vue init
document.addEventListener("DOMContentLoaded", () => {
  new Vue({
    el: "#vue-app",
    store,
    created() {
      // 取回購物車詢價車資料
      this.$store.dispatch("get_items_in_sale_cart");
      this.$store.dispatch("get_items_in_query_cart");
      this.$store.dispatch("get_items_in_gift_cart");
      this.$store.dispatch("get_cart_data");
    },
  });
});

// 套件使用
Vue.component("ValidationObserver", ValidationObserver);
Vue.component("ValidationProvider", ValidationProvider);

// components
Vue.component("add-to-query-cart-btn", AddToJqueryCartBtn);
Vue.component("add-to-query-cart-form", AddToQueryCartForm);
Vue.component("add-to-sale-cart-btn", AddToSaleCartBtn);
Vue.component("add-to-sale-cart-form", AddToSaleCartForm);
Vue.component("cart-amount-data", CartAmountData);
Vue.component("mobile-query-cart-icon", MobileQueryCartIcon);
Vue.component("mobile-sale-cart-icon", MobileSaleCartIcon);
Vue.component("query-cart-icon", QueryCartIcon);
Vue.component("query-cart-item-row", QueryCartItemRow);
Vue.component("query-mini-item", QueryMiniItem);
Vue.component("sale-cart-icon", SaleCartIcon);
Vue.component("sale-cart-item-row", SaleCartItemRow);
Vue.component("sale-mini-item", SaleMiniItem);
Vue.component("checkout-btn", CheckoutBtn);
Vue.component("add-gift-form", AddGiftForm);
Vue.component("cart-gift", CartGift);
// Vue.component('card-canvas', CardCanvas);
// Vue.component('card-canvas2', CardCanvas2);
// Vue.component('create-card-btn', CreateCardBtn);
Vue.component("Steps", Steps);
Vue.component("new-step", NewStep);
