import Vue from 'vue/dist/vue.esm';
import Vuex from 'vuex';
import axios from 'axios';

Vue.use(Vuex);

var csrf = $("meta[name=csrf-token]").attr("content");
axios.defaults.headers.common['X-CSRF-Token'] = csrf;

const store = new Vuex.Store({
  state: {
    items_in_sale_cart: [],
    items_in_query_cart: [],
    items_in_gift_cart: [],
    freight: 0,
    cart_amount: 0,
    sale_amount: 0,
    coupon: {
      discount: 0,
      coupon_no: ""
    }
  },
  mutations: {
    set_items_in_sale_cart: function(state, payload) {
      state.items_in_sale_cart = payload.updated_sale_cart;
      state.cart_amount = payload.cart_amount;
      state.sale_amount = payload.sale_amount;
      state.freight = payload.freight
      // 如果有更新的加價購
      if (payload.updated_gift_cart) {
        state.items_in_gift_cart = payload.updated_gift_cart;
      }
      return state;
    },
    set_items_in_query_cart: function(state, payload) {
      state.items_in_query_cart = payload.updated_query_cart;
      return state
    },
    set_items_in_gift_cart: function(state, payload) {
      state.items_in_gift_cart = payload.updated_gift_cart;
      state.cart_amount = payload.cart_amount;
      state.sale_amount = payload.sale_amount;
      state.freight = payload.freight;
      return state;
    },
    get_cart_data: function(state, payload) {
      state.cart_amount = payload.cart_amount;
      state.sale_amount = payload.sale_amount;
      state.freight = payload.freight;
      return state;
    },
    empty_gifts: function(state) {
      state.items_in_gift_cart = [];
      return state;
    },
    coupon_check: function(state, payload) {
      state.coupon = payload.coupon;
      return state;
    }
  },
  actions: {
    get_items_in_sale_cart: function(context, payload) {
      axios.get('/api/cart/get_items_in_sale_cart')
           .then(function(response){
            context.commit('set_items_in_sale_cart', response.data)
           }).catch(function(error) {
             console.log(error);
           })
    },
    get_items_in_query_cart: function(context, payload) {
      axios.get('/api/cart/get_items_in_query_cart')
           .then(function(response){
            //  console.log(response)
            context.commit('set_items_in_query_cart', response.data)
           }).catch(function(error) {
             console.log(error);
           })
    },
    get_items_in_gift_cart: function(context, payload) {
      axios.get('/api/cart/get_items_in_gift_cart')
           .then(function(response){
            //  console.log(response)
            context.commit('set_items_in_gift_cart', response.data)
           }).catch(function(error) {
             console.log(error);
           })
    },
    addToSaleCart: function(context, payload) {
      var qty = payload.qty || 1;
      axios.post(`/api/cart/${payload.key}/add_to_sale_cart`, {qty: qty})
           .then(function(response) {
            // 更新items_in_sale_cart
            context.commit('set_items_in_sale_cart', response.data);
            // 更新運費
            store.dispatch("get_cart_data");
           }).catch(function(error) {
             console.log(error);
           })
    },
    addToQueryCart: function(context, payload) {
      var qty = payload.qty || 1;
      axios.post(`/api/cart/${payload.key}/add_to_query_cart`, {qty: qty})
           .then(function(response) {
            // 更新items_in_sale_cart
            context.commit('set_items_in_query_cart', response.data)
           }).catch(function(error) {
             console.log(error);
           })
    },
    addToGiftCart: function(context, payload) {
      var qty = payload.qty || 1;
      var url = `/api/cart/${payload.key}/add_to_gift_cart`;
      axios.post(url, {qty: qty})
           .then(function(response){
             var data = response.data;
             if (data.status === "success") {
              context.commit('set_items_in_gift_cart', response.data)
              if (!payload.no_message) {
                sweetAlert({
                  title: "",
                  text: "成功加入購物車!",
                  type: "success"
                });
              }
             } else {
               if (!payload.no_message) {
                sweetAlert({
                  title: "",
                  text: "加購商品未達金額目標或找不到加價購依附的商品",
                  type: "warning"
                });
               }
             }
           }).catch(function(error) {
             console.log(error);
           })

    },
    rm_item_from_sale_cart: function(context, payload) {
      axios.delete(`/api/cart/${payload.key}/rm_item_from_sale_cart`)
           .then(function(response) {
            // 更新items_in_sale_cart
            context.commit('set_items_in_sale_cart', response.data);
           }).catch(function(error) {
             console.log(error);
           })
    },
    rm_item_from_query_cart: function(context, payload) {
      axios.delete(`/api/cart/${payload.key}/rm_item_from_query_cart`)
           .then(function(response) {
            // 更新items_in_sale_cart
            context.commit('set_items_in_query_cart', response.data);
           }).catch(function(error) {
             console.log(error);
           })
    },
    rm_item_from_gift_cart: function(context, payload) {
      axios.delete(`/api/cart/${payload.key}/rm_item_from_gift_cart`)
           .then(function(response) {
            // 更新items_in_sale_cart
            context.commit('set_items_in_gift_cart', response.data);
           }).catch(function(error) {
             console.log(error);
           })
    },
    get_cart_data: function(context, payload) {
      axios.get("/api/cart/get_cart_data")
           .then(function(response){
             context.commit('get_cart_data', response.data);
           }).catch(function(error) {
             console.log(error);
           })
    },
    empty_gifts: function(context, payload) {
      var url = "/api/cart/empty_gifts"
      axios.delete(url)
           .then(function(response){
            context.commit('empty_gifts');
           }).catch(function(error){
             console.log(error);
           })
    },
    coupon_check: function(context, payload) {
      var url = "/api/cart/coupon_check";
      axios.post(url, {
        coupon_no: payload.coupon_no
      }).then(function(response){
        context.commit('coupon_check', response.data);
        // sweet alert
        swal(response.data.message);
      }).catch(function(error){
        console.log(error)
      })
    }
  },
  getters: {
  }
});

export default store;