<template>
  <a rel="nofollow" class="btn btn-cart" @click="addToCart">加入詢價車</a>
</template>

<script>
export default {
  props: ['item_key'],
  methods: {
    addToCart: function() {
      // 取得當前的key與qty
      // 加入購物車
      this.$store.dispatch('addToQueryCart', {key: this.item_key});
      sweetAlert({
        title: "",
        text: "成功加入詢價車!",
        type: "success"
      });
    }
  }
}
</script>