<template>
  <div class='mobile-menu-btn' v-if='$store.state.items_in_query_cart.length > 0' @click='goToQueryCart'><div class='mobile-search-btn'><i class='lnr lnr-briefcase'></i></div></div>
</template>

<script>
export default {
  methods: {
    goToQueryCart: function() {
      window.location.href = '/query_cart';
    }
  }
}
</script>