<template>
  <div class="modal fade" :id="dom_id">
    <div :class="`modal-dialog ${is_mobile ? '' : 'w-25'}`">
      <div class="modal-content">
        <div class="modal-header">
          <button type="button" class="close" data-dismiss="modal">
            <span aria-hidden="true">&times;</span><span class="sr-only">Close</span>
          </button>
        </div>
        <div class="modal-body">
          <!-- 編輯階段 -->
          <div v-if="stage == 'edit'">
            <p class="tac red"><strong>*請點選希望放置您的廣告圖片位置</strong></p>
            <form class="form-horizontal mt20" @submit.prevent="setPreview">
              <!-- layout0說明圖 -->
              <div id="direction-grid" class="flex mt10">
                <div class="grid">
                  <div class="cell"></div>
                  <div
                    :class="`cell radio-top ${
                      custom_image_position === 'top' ? 'selected-position' : ''
                    }`"
                    @click.prevent="() => changePosition('top')"
                  >
                    <label @click.prevent="() => changePosition('top')"
                      ><span class="circle">
                        <span v-show="custom_image_position === 'top'" class="inner-circle"></span>
                      </span>
                      &nbsp;<span class="big-font">上</span></label
                    >
                  </div>
                  <div class="cell"></div>
                  <div
                    :class="`cell radio-left ${
                      custom_image_position === 'left' ? 'selected-position' : ''
                    }`"
                    @click.prevent="() => changePosition('left')"
                  >
                    <label @click.prevent="() => changePosition('left')">
                      <span class="circle">
                        <span v-show="custom_image_position === 'left'" class="inner-circle"></span>
                      </span>
                      &nbsp;<span class="big-font">左</span></label
                    >
                  </div>

                  <div class="cell"><img :src="layout0_position" alt="" /></div>
                  <div
                    :class="`cell radio-right ${
                      custom_image_position === 'right' ? 'selected-position' : ''
                    }`"
                    @click.prevent="() => changePosition('right')"
                  >
                    <label @click.prevent="() => changePosition('right')">
                      <span class="circle">
                        <span v-show="custom_image_position === 'right'" class="inner-circle"></span>
                      </span>
                      &nbsp;<span class="big-font">右</span></label
                    >
                    </label>
                  </div>
                  <div class="cell"></div>
                  <div
                    :class="`cell radio-bottom ${
                      custom_image_position === 'bottom' ? 'selected-position' : ''
                    }`"
                    @click.prevent="() => changePosition('bottom')"
                  >
                    <label @click.prevent="() => changePosition('bottom')">
                      <span class="circle">
                        <span v-show="custom_image_position === 'bottom'" class="inner-circle"></span>
                      </span>
                      &nbsp;<span class="big-font">下</span></label
                    >
                    </label>
                  </div>
                  <div class="cell"></div>
                </div>
              </div>
              <hr />
              <!-- 目前附加圖片 -->
              <div class="flex mt10">
                <div class="fx1">
                  <p class="flex" style="justify-content: space-between">
                    使用廣告圖片:
                    <label :style="custom_image && custom_image.length > 0 ? '' : 'color: #ccc'"
                      ><input
                        type="radio"
                        v-model="deal_with_custom_image"
                        value="keep"
                        :disabled="!custom_image || custom_image.length === 0"
                      />&nbsp;使用目前</label
                    >
                    <label
                      ><input
                        type="radio"
                        v-model="deal_with_custom_image"
                        value="change"
                      />&nbsp;更換圖片</label
                    >
                    <label
                      ><input
                        type="radio"
                        v-model="deal_with_custom_image"
                        value="remove"
                      />&nbsp;刪除圖片</label
                    >
                  </p>
                  <img
                    v-if="
                      custom_image && custom_image.length > 0 && deal_with_custom_image === 'keep'
                    "
                    :src="custom_image"
                    style="width: 100%"
                  />
                </div>
              </div>

              <!-- 附加圖片 -->
              <div v-if="deal_with_custom_image === 'change'" class="flex mt10">
                <div class="fx1 posr">
                  <input
                    type="file"
                    class="form-control"
                    ref="custom_image"
                    accept="image/*"
                    @change.prevent="uploadFile()"
                  />
                  <div class="posa" style="right: 10px; top: 6px">
                    <label>可上傳廣告文宣</label>
                  </div>
                </div>
              </div>
              <!-- submit -->
              <div class="flex mt10">
                <div class="fx1 flex flex_center">
                  <!-- 20240524要求隱藏 <a
                    href="#!"
                    class="btn btn__bg"
                    style="background-color: #0094ea"
                    @click.prevent="openOtherModal"
                    >其他版型</a
                  >&nbsp; -->
                  <button type="submit" class="btn btn__bg" :disabled="previewing">
                    {{ previewing ? "處理中..." : "預覽" }}
                  </button>
                </div>
              </div>
            </form>
          </div>
          <!-- 預覽與完成階段 -->
          <div v-if="stage === 'preview'">
            <div class="mt5 flex flex_center">
              <img :src="preview_card_path" />
            </div>
            <div class="mt20 tac">
              <a href="#" @click="stage = 'edit'" class="btn btn__bg">上一步</a>&nbsp;&nbsp;
              <a href="#" class="btn btn__bg" @click.prevent="createCard">
                <i class="far fa-clipboard"></i>{{ generating ? "處理中..." : "確定" }}
              </a>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import axios from "axios";

import { extend } from "vee-validate";
import { required } from "vee-validate/dist/rules";

extend("required", {
  ...required,
  message: "必填",
});

export default {
  props: [
    "is_mobile",
    "layout0_position",
    "other_modal",
    "default_custom_image",
    "preview_url",
    "generate_url",
    "warning",
    "dom_key",
    "points",
    "pointship_edit_hours"
  ],
  data() {
    return {
      dom_id: this.dom_key || "independent-page-modal",
      custom_image: this.default_custom_image,
      custom_image_position: "top",
      generating: false,
      previewing: false,
      use_custom_image: true,
      stage: "edit",
      deal_with_custom_image: this.default_custom_image ? "keep" : "change",
      preview_card_path: null,
    };
  },
  methods: {
    changePosition(position) {
      this.custom_image_position = position;
    },
    openOtherModal() {
      $("#" + this.dom_key).modal("hide");
      // 修正多組進階版型時users/_files找不到id的問題
      if ($(".steps-modal").length > 1) {
        $("body").addClass("modal-open");
        $(`#${this.other_modal}`).modal("show");
      } else {
        $("body").addClass("modal-open");
        $(`${this.other_modal}`).modal("show");
      }
    },
    uploadFile() {
      const file = this.$refs.custom_image.files[0];
      const self = this;
      let width = 1273;
      // 無檔案就不執行
      if (!file) return;
      // 繼續執行並更換custom_image，把deal_with_custom_image設為keep
      const reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onload = function (event) {
        const imgElement = document.createElement("img");
        imgElement.src = event.target.result;
        imgElement.onload = function (evt) {
          const canvas = document.createElement("canvas");
          const MAX_WIDTH = width;
          const ratio = MAX_WIDTH / evt.target.width;
          canvas.width = MAX_WIDTH;
          canvas.height = evt.target.height * ratio;
          const ctx = canvas.getContext("2d");
          ctx.drawImage(evt.target, 0, 0, canvas.width, canvas.height);
          const srcEncoded = ctx.canvas.toDataURL("image/jpeg", 0.8);
          self.custom_image = srcEncoded;
        };
      };
      this.deal_with_custom_image = "keep";
    },
    setPreview() {
      this.previewing = true;
      setTimeout(() => {
        this.previewing = false;
      }, 5000);
      const postData = {
        layout: "layout0",
        custom_image_position: this.custom_image_position,
        custom_image: this.deal_with_custom_image === "remove" ? "" : this.custom_image,
        use_custom_image: this.deal_with_custom_image === "remove" ? false : true,
        deal_with_custom_image: this.deal_with_custom_image,
      };
      axios
        .post(this.preview_url, postData)
        .then(({ data }) => {
          if (data.status === "success") {
            // console.log(data)
            this.preview_card_path = data.preview_card_path;
            this.stage = "preview";
            // 如果deal_with_custom_image是remove，就把custom_image清空
            if (this.deal_with_custom_image === "remove") {
              this.custom_image = "";
            }
          } else {
            // 錯誤，關閉並顯示錯誤訊息
            $("#" + this.dom_key).modal("hide");
            swal({
              type: "error",
              icon: "warning",
              text: data.message,
            });
          }
        })
        .catch((err) => console.log(err));
    },
    createCard() {
      this.generating = true;
      // 關閉modal
      $("#" + this.dom_key).modal("hide");
      setTimeout(() => {
        this.generating = false;
        this.stage = "edit";
      }, 5000);
      const { dom_id, is_mobile, generate_url } = this;
      const postData = {
        layout: "layout0",
        custom_image_position: this.custom_image_position,
        custom_image: this.custom_image,
        use_custom_image: this.deal_with_custom_image === "remove" ? false : true,
        deal_with_custom_image: this.deal_with_custom_image,
      };
      // 測試是否屬於多版本圖片
      if (dom_id !== "independent-page-modal") {
        const independentPageImageKey = dom_id.split("_")[1];
        postData.independent_page_image_key = independentPageImageKey;
      }

      if (this.warning) {
        swal
          .fire({
            title: "確認製作P圖",
            text: `將扣除${this.points}點，確認後可在${this.pointship_edit_hours}小時內免費編輯。確認進行?`,
            showCancelButton: true,
            confirmButtonColor: "#3085d6",
            cancelButtonColor: "#d33",
            confirmButtonText: "製作",
            cancelButtonText: "取消",
            reverseButtons: true,
          })
          .then((result) => {
            if (result.value) {
              postForm(generate_url, is_mobile, postData);
            }
            // 清空
            this.preview_card_path = null;
            // 不是mobile就reload
            if (!is_mobile) {
              setTimeout(() => {
                location.reload();
              }, 4000);
            }
          });
      } else {
        postForm(generate_url, is_mobile, postData);
        // 清空
        this.preview_card_path = null;
        $("#" + this.dom_key).modal("hide");
        // 不是mobile就reload
        if (!is_mobile) {
          setTimeout(() => {
            location.reload();
          }, 4000);
        }
      }
    },
  },
};

function postForm(url, is_mobile, params) {
  // 創建form
  var temp_form = document.createElement("form");
  // 設置form
  temp_form.action = url;
  // 手機版無需另開視窗
  if (!is_mobile) {
    temp_form.target = "_blank";
  }
  temp_form.method = "post";
  temp_form.style.display = "none";
  // 處理需要傳遞的參數
  for (var x in params) {
    var opt = document.createElement("textarea");
    opt.name = x;
    opt.value = params[x];
    temp_form.appendChild(opt);
  }

  var token = document.createElement("input");
  token.name = "authenticity_token";
  token.value = $("meta[name=csrf-token]").attr("content");
  temp_form.appendChild(token);
  document.body.appendChild(temp_form);
  // 提交表單
  temp_form.submit();
}
</script>
