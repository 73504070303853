<template>
  <div>
    <div class="quantity-cart-box d-flex align-items-center">
      <h5>規格:</h5>
      <ul class="li-inline">
        <li v-for="item_specification in item_specifications">{{item_specification.title}}</li>
      </ul>
    </div>
    <div class="quantity-cart-box d-flex align-items-center">
      <h5>數量:</h5>
      <div class="quantity">
        <div class="pro-qty" style="width:110px">
          <span @click="minus" class="dec qtybtn">-</span>
          <input type="number" v-model="qty" step="1" min="1" style="width:48px">
          <span @click="plus" class="inc qtybtn">+</span>
        </div>
      </div>
      <div class="action_link">
        <button type="button" class="btn btn-cart2" @click="addTo">加入詢價車</button>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: ['item_key', 'current_qty', 'item_specifications'],
  data: function() {
    return {
      qty: parseInt(this.current_qty) || 1
    }
  },
  methods: {
    addTo: function() {
      this.$store.dispatch('addToQueryCart', {key: this.item_key, qty: this.qty});
      sweetAlert({
        title: "",
        text: "成功加入詢價車!",
        type: "success"
      });
    },
    plus: function() {
      this.qty = this.qty + 1;
    },
    minus: function() {
      this.qty = this.qty - 1
      if (this.qty <= 0) {
        this.qty = 1
      }
    }
  }
}
</script>