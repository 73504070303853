<template>
  <ul>
    <li v-for="item in items" class="minicart-item query-mini-item" :key="item.key">
      <div class="minicart-thumb">
        <a :href="'/items/' + item.key">
          <img :src="item.image" :alt="item.title">
        </a>
      </div>
      <div class="minicart-content">
        <h3 class="product-name">
        <a :href="'/items/' + item.key">{{item.title}}</a>
        </h3>
        <p>{{item.intro}}</p>
      </div>
      <button @click="rm_item(item)" class="minicart-remove"><i class="lnr lnr-cross"></i></button>
    </li>
  </ul>
</template>

<script>
export default {
  computed: {
    items: function() {
      return this.$store.state.items_in_query_cart;
    }
  },
  methods: {
    rm_item: function(item) {
      this.$store.dispatch('rm_item_from_query_cart', item).then(function(){
        // 判斷minicart-item的數量決定是否關閉
        if ($(".query-mini-item").length <= 1) {
          $("body").removeClass('fix');
          $(".query-cart-inner").removeClass('show');
        }
      });
    }
  }
}
</script>