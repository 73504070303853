<template>
  <div class="quantity-cart-box d-flex align-items-center">
    <h5>數量:</h5>
    <div class="quantity">
      <div class="pro-qty">
        <span @click="minus" class="dec qtybtn">-</span>
        <input type="text" v-model="qty">
        <span @click="plus" class="inc qtybtn">+</span>
      </div>
    </div>
    <div class="action_link">
      <button type="button" class="btn btn-cart2" @click="addTo">加入購物車</button>
    </div>
  </div>
</template>

<script>
import axios from 'axios';
var csrf = $("meta[name=csrf-token]").attr("content");
axios.defaults.headers.common['X-CSRF-Token'] = csrf;

export default {
  props: ["max_qty", "gift_key"],
  data() {
    return {
      qty: 1
    }
  },
  methods: {
    plus: function() {
      if (this.qty + 1 <= this.max_qty) {
        this.qty += 1;
      }
    },
    minus: function() {
      this.qty = this.qty - 1
      if (this.qty <= 0) {
        this.qty = 1;
      }
    },
    addTo: function() {
      this.$store.dispatch('addToGiftCart', {key: this.gift_key, qty: this.qty});
      // 重設qty
      this.qty = 1;
      // 關閉modal
      $(".modal").modal("hide");
    },
  }
}
</script>