<template>
  <div id="cart-gift-wrapper">
    <!-- 如果只有1筆 -->
    <div v-if="gifts.length === 1 ">
      <div class="slide product-item" v-for="(gift, index) in gifts" :key="`gift_${index}`" :style="`width:${mobile_mode ? '100%' : '240px'}`">
        <figure class="product-thumb image-container">
          <a href="#" @click="openModal(index)">
            <img :src="gift.image_url">
            <div class="overlay" v-if="showQualifyTarget(gift)">還差 {{gift.qualify_amount - sale_amount}}元 就能加購！</div>
          </a>
        </figure>
        <div class="product-caption">
          <p class="product-name">{{gift.title}}</p>
          <div class="price-box">
            <span class="price-regular">${{gift.price}}</span>
          </div>
        </div>
      </div>
    </div>
    <!-- 迴圈 -->
    <agile
      v-if="gifts.length >= 2"
      :key="gifts.length"
      :options="carouselOptions">
      <div class="slide product-item" v-for="(gift, index) in gifts" :key="`gift_${index}`">
        <figure class="product-thumb image-container">
          <a href="#" @click="openModal(index)">
            <img :src="gift.image_url">
            <div class="overlay" v-if="showQualifyTarget(gift)">還差 {{gift.qualify_amount - sale_amount}}元 就能加購！</div>
          </a>
        </figure>
        <div class="product-caption">
          <p class="product-name">{{gift.title}}</p>
          <div class="price-box">
            <span class="price-regular">${{gift.price}}</span>
          </div>
        </div>
      </div>
    </agile>
    <!-- modal -->
    <div id="gift-modal-container" class="modal">
      <div class="modal-dialog modal-lg modal-dialog-centered">
        <div class="modal-content">
          <div class="modal-header">
            <button type="button" class="close" data-dismiss="modal">&times;</button>
          </div>
          <div class="modal-body">
            <!-- product details inner end -->
            <div class="product-details-inner">
              <div class="row">
                <div class="col-lg-5 col-md-5">
                  <div class="pro-large-img img-zoom">
                    <img :src="selectedGift.image_url">
                  </div>
                </div>
                <div class="col-lg-7 col-md-7">
                  <div class="product-details-des quick-details">
                    <h3 class="product-name">{{selectedGift.title}}</h3>
                    <div class="price-box">
                      <span class="price-regular">${{selectedGift.price}}</span>
                      <span v-if="showQualifyTarget(selectedGift)">(購買金額還差 {{selectedGift.qualify_amount - sale_amount}}元 就能加購！)</span>
                    </div>
                    <p class="pro-desc">{{selectedGift.intro}}</p>
                    <add-gift-form
                      v-if="!showQualifyTarget(selectedGift)"
                      :max_qty="selectedGift.max_qty"
                      :gift_key="selectedGift.key"
                    ></add-gift-form>
                  </div>
                </div>
              </div>
            </div>
            <!-- product details inner end -->
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import axios from 'axios';
import { VueAgile } from 'vue-agile'
var csrf = $("meta[name=csrf-token]").attr("content");
axios.defaults.headers.common['X-CSRF-Token'] = csrf;

export default {
  components: {
      agile: VueAgile
  },
  props: ["mobile_mode"],
  data() {
    return {
      gifts: [],
      selectedGift: {},
      carouselOptions: {
        navButtons: false,
        dots: false,
        autoplay: true,
        centerMode: true,
        responsive: [
          {
            breakpoint: 1200,
            settings: {
              slidesToShow: 5
            }
          },
          {
            breakpoint: 992,
            settings: {
              slidesToShow: 4
            }
          },
          {
            breakpoint: 768,
            settings: {
              slidesToShow: 3
            }
          },
          {
            breakpoint: 576,
            settings: {
              slidesToShow: 1
            }
          },
        ]
      }
    }
  },
  computed: {
    cart_amount: function() {
      return this.$store.state.cart_amount;
    },
    sale_amount: function() {
      return this.$store.state.sale_amount;
    }
  },
  watch: {
    cart_amount(newVal) {
      const self = this;
      const url = `/api/cart/possible_gifts`;
      axios.get(url).then(function({data}){
        if (data.status === "success") {
          // console.log(data);
          self.gifts = data.gifts
        }
      }).catch((error) => console.log(error));
    }
  },
  methods: {
    openModal(index) {
      this.selectedGift = this.gifts[index];
      $("#gift-modal-container").modal("show");
    },
    showQualifyTarget(gift) {
      return gift.gift_type === 'by_amount' && (gift.qualify_amount - this.sale_amount) > 0
    }
  },
  created() {
    const self = this;
    const url = `/api/cart/possible_gifts`;
    axios.get(url).then(function({data}){
      if (data.status === "success") {
        // console.log(data);
        self.gifts = data.gifts
      }
    }).catch((error) => console.log(error));
  },
}
</script>

<style>
.product-name {
  font-size: 18px;
}
.image-container {
  position: relative;
}

/* Make the image to responsive */
.image-container img {
  display: block;
  width: 100%;
  height: auto;
}

.overlay {
  position: absolute;
  bottom: 0;
  background: rgb(0, 0, 0);
  background: rgba(0, 0, 0, 0.5); /* Black see-through */
  color: #f1f1f1;
  width: 100%;
  transition: .5s ease;
  color: white;
  font-size: 16px;
  padding: 10px;
  text-align: center;
}

</style>