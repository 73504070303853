<template>
  <div class="modal fade steps-modal" :id="dom_id">
    <div :class="`modal-dialog ${is_mobile ? '' : 'w-25'}`">
      <div class="modal-content">
        <div class="modal-header">
          <button type="button" class="close" data-dismiss="modal">
            <span aria-hidden="true">&times;</span><span class="sr-only">Close</span>
          </button>
        </div>
        <div class="modal-body">
          <!-- steps -->
          <div class="flex">
            <div class="fx1">
              <div class="step-container">
                <p :style="`${currentStep === 1 ? 'color:red;font-weight:800' : ''}`">
                  1. 選擇版型
                </p>
              </div>
            </div>
            <div class="fx1">
              <div class="step-container">
                <p :style="`${currentStep === 2 ? 'color:red;font-weight:800' : ''}`">
                  2. 填寫資料
                </p>
              </div>
            </div>
            <div class="fx1">
              <div class="step-container">
                <p :style="`${currentStep === 3 ? 'color:red;font-weight:800' : ''}`">3. 製作P圖</p>
              </div>
            </div>
          </div>
          <keep-alive>
            <!-- step1 -->
            <step1
              v-if="currentStep === 1"
              :dom_id="dom_id"
              :layout_groups="layout_groups"
              @goTo="goTo"
              @arrangeData="arrangeData"
              :layout0_img="layout0_img"
              :layout1_img="layout1_img"
              :layout2_img="layout2_img"
              :layout3_img="layout3_img"
              :layout4_img="layout4_img"
              :layout5_img="layout5_img"
              :layout6_img="layout6_img"
            ></step1>
            <!-- step2 -->
            <step2
              v-if="currentStep === 2"
              :layout0_position="layout0_position"
              @goTo="goTo"
              @arrangeData="arrangeData"
              @setPreview="setPreview"
              :user_data="user"
              :layout="allData.step1 || layout"
            ></step2>
          </keep-alive>
          <!-- step3 -->
          <step3
            v-if="currentStep === 3"
            @goTo="goTo"
            :preview_card_path="preview_card_path"
            @createCard="createCard"
          ></step3>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Step1 from "./step1";
import Step2 from "./step2";
import Step3 from "./step3";
import axios from "axios";

var csrf = $("meta[name=csrf-token]").attr("content");
axios.defaults.headers.common["X-CSRF-Token"] = csrf;

export default {
  components: { Step1, Step2, Step3 },
  props: [
    "dom_key",
    "is_mobile",
    "user_data",
    "layout0_position",
    "layout0_img",
    "layout1_img",
    "layout2_img",
    "layout3_img",
    "layout4_img",
    "layout5_img",
    "layout6_img",
    "preview_url",
    "generate_url",
    "warning",
    "points",
    "layout_groups",
    "pointship_edit_hours",
  ],
  data() {
    return {
      dom_id: this.dom_key || "independent-page-modal",
      currentStep: 1,
      accessibleSteps: [1],
      allData: {},
      preview_card_path: null,
      layout: "layout1",
      user: this.user_data || {},
    };
  },
  methods: {
    goTo: function (step) {
      this.currentStep += step;
    },
    setTo: function (step) {
      if (this.accessibleSteps.includes(step)) {
        this.currentStep = step;
      }
    },
    arrangeData: function (data, step) {
      this.allData[`step${step}`] = data;
      // 將可存取的步數加入
      this.accessibleSteps = [...new Set(this.accessibleSteps), step + 1];
    },
    setPreview: function () {
      const { allData } = this;
      const postData = {
        layout: allData.step1,
        ...allData.step2,
      };
      // 測試是否屬於多版本圖片
      if (this.dom_id !== "independent-page-modal") {
        const independentPageImageKey = this.dom_id.split("_")[1];
        postData.independent_page_image_key = independentPageImageKey;
      }
      axios
        .post(this.preview_url, postData)
        .then(({ data }) => {
          console.log(data);
          if (data.status === "success") {
            // console.log(data)
            this.preview_card_path = data.preview_card_path;
            this.accessibleSteps = [1, 2, 3];
            this.setTo(3);
          } else {
            // 錯誤，關閉並顯示錯誤訊息
            $("#" + this.dom_id).modal("hide");
            swal({
              type: "error",
              icon: "warning",
              text: data.message,
            });
          }
        })
        .catch((err) => console.log(err));
    },
    createCard() {
      const { dom_id, is_mobile, allData, generate_url } = this;
      const postData = {
        layout: allData.step1,
        ...allData.step2,
      };
      // 測試是否屬於多版本圖片
      if (dom_id !== "independent-page-modal") {
        const independentPageImageKey = dom_id.split("_")[1];
        postData.independent_page_image_key = independentPageImageKey;
      }

      if (this.warning) {
        swal
          .fire({
            title: "確認製作P圖",
            text: `將扣除${this.points}點，確認後可在${this.pointship_edit_hours}小時內免費編輯。確認進行?`,
            showCancelButton: true,
            confirmButtonColor: "#3085d6",
            cancelButtonColor: "#d33",
            confirmButtonText: "製作",
            cancelButtonText: "取消",
            reverseButtons: true,
          })
          .then((result) => {
            if (result.value) {
              postForm(generate_url, is_mobile, postData);
            }
            // 清空
            this.layout = "layout1";
            this.currentStep = 1;
            (this.accessibleSteps = [1]), (this.preview_card_path = null);
            // 不是mobile就reload
            if (!is_mobile) {
              setTimeout(() => {
                location.reload();
              }, 4000);
            }
          });
      } else {
        postForm(generate_url, is_mobile, postData);
        // 清空
        this.layout = "layout1";
        this.currentStep = 1;
        (this.accessibleSteps = [1]), (this.preview_card_path = null);
        $("#" + this.dom_id).modal("hide");
        // 不是mobile就reload
        if (!is_mobile) {
          setTimeout(() => {
            location.reload();
          }, 4000);
        }
      }
    },
  },
};

function postForm(url, is_mobile, params) {
  // 創建form
  var temp_form = document.createElement("form");
  // 設置form
  temp_form.action = url;
  // 手機版無需另開視窗
  if (!is_mobile) {
    temp_form.target = "_blank";
  }
  temp_form.method = "post";
  temp_form.style.display = "none";
  // 處理需要傳遞的參數
  for (var x in params) {
    var opt = document.createElement("textarea");
    opt.name = x;
    opt.value = params[x];
    temp_form.appendChild(opt);
  }

  var token = document.createElement("input");
  token.name = "authenticity_token";
  token.value = $("meta[name=csrf-token]").attr("content");
  temp_form.appendChild(token);
  document.body.appendChild(temp_form);
  // 提交表單
  temp_form.submit();
}
</script>
