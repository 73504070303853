<template>
  <li v-if='$store.state.items_in_sale_cart.length > 0' @click='showSideCart'>
    <a id='sale-cart-btn' href='#'>
      <i class='lnr lnr-cart'></i>
      <div class='notification'>{{$store.state.items_in_sale_cart.length + $store.state.items_in_gift_cart.length}}</div>
    </a>
  </li>
</template>

<script>
export default {
  methods: {
    showSideCart: function() {
      $("body").addClass('fix');
      $(".sale-cart-inner").addClass('show');
    }
  }
}
</script>