<template>
  <div class='mobile-menu-btn' v-if='$store.state.items_in_sale_cart.length > 0' @click='goToSaleCart'><div class='mobile-search-btn'><i class='lnr lnr-cart'></i></div></div>
</template>

<script>
export default {
  methods: {
    goToSaleCart: function() {
      window.location.href = '/sale_cart';
    }
  }
}
</script>