<template>
  <tbody>
    <tr v-for="item in items">
      <td class="pro-thumbnail">
        <a :href="'/items/' + item.key"><img class="img-fluid" :src="item.image"></a>
      </td>
      <td class="pro-title">
        <a :href="'/items/' + item.key">{{item.title}}</a>
      </td>
      <td class="pro-intro">
        <p>{{item.intro}}</p>
      </td>
      <td class="pro-quantity">
        <div class="pro-qty">
        <span @click="minus(item)" class="dec qtybtn">-</span>
        <input class="input-width" :ref="'input_' + item.key" type="number" :value="item.qty" step="1" min="1" @keyup="updateQty(item)">
        <span @click="plus(item)" class="inc qtybtn">+</span>
        </div>
      </td>
      <td class="pro-remove"><a href="#" @click="rm_item(item)"><i class="far fa-trash-alt"></i></a></td>
    </tr>
  </tbody>
</template>

<script>
export default {
  computed: {
    items: function() {
      return this.$store.state.items_in_query_cart;
    }
  },
  methods: {
    rm_item: function(item) {
      this.$store.dispatch('rm_item_from_query_cart', item)
    },
    plus: function(item) {
      this.$store.dispatch('addToQueryCart', {key: item.key, qty: parseInt(item.qty) + 1});
    },
    minus: function(item) {
      if (item.qty - 1 > 0) {
        this.$store.dispatch('addToQueryCart', {key: item.key, qty: parseInt(item.qty) - 1});
      } else {
        sweetAlert({
          title: "",
          text: "數量不可小於1",
          type: "warning"
        });
      }
    },
    updateQty: function(item) {
      var value = parseInt(this.$refs['input_' + item.key][0].value);
      console.log(value);
      this.$store.dispatch('addToQueryCart', {key: item.key, qty: value});
    }
  },
  watch: {
    items: function(newVal) {
      if (!newVal[0]) {
        sweetAlert({
          title: "",
          text: "詢價車被清空！",
          type: "warning"
        }).then(function(){
          window.location.href = '/';
        });
      }
    }
  }
}
</script>