<template>
  <div id="checkout-container">
    <div v-if="$store.state.items_in_query_cart.length > 0" id="queryout-btn" @click="queryCheckout">
    <i class="fas fa-briefcase"></i>
    </div>
    <div v-if="$store.state.items_in_sale_cart.length > 0" id="checkout-btn" @click="checkout">
      <i class="fas fa-shopping-cart"></i>
    </div>
  </div>
</template>

<script>
export default {
  props: ['item_key'],
  methods: {
    checkout: function() {
      window.location.href = '/sale_cart';
    },
    queryCheckout: function() {
      window.location.href = '/query_cart';
    }
  }
}
</script>
