<template>
  <div class="p-step1-container">
    <!-- 選擇樣式 -->
    <!-- type0 20230220 增加只有圖片上傳的版型 -->
    <div v-show="layout_groups.includes('layout_group0')" class="flex" style="margin-top: 20px">
      <div class="fx1 flex_center">
        <label class="layout-option-warpper">
          <img :src="layout0_img" style="width: 370px" />
          <br />
          <input type="radio" v-model="layout" value="layout0" class="layout-option" />
        </label>
      </div>
    </div>
    <!-- type1 -->
    <div v-show="layout_groups.includes('layout_group1')" class="flex">
      <div class="fx1 flex_center">
        <label class="layout-option-warpper">
          <img :src="layout1_img" />
          <br />
          <input type="radio" v-model="layout" value="layout1" class="layout-option" />
        </label>
      </div>
      <div class="fx1 flex_center">
        <label class="layout-option-warpper">
          <img :src="layout2_img" />
          <br />
          <input type="radio" v-model="layout" value="layout2" class="layout-option" />
        </label>
      </div>
    </div>
    <!-- type2 -->
    <div v-show="layout_groups.includes('layout_group2')" class="flex">
      <div class="fx1 flex_center">
        <label class="layout-option-warpper">
          <img :src="layout3_img" />
          <br />
          <input type="radio" v-model="layout" value="layout3" class="layout-option" />
        </label>
      </div>
      <div class="fx1 flex_center">
        <label class="layout-option-warpper">
          <img :src="layout4_img" />
          <br />
          <input type="radio" v-model="layout" value="layout4" class="layout-option" />
        </label>
      </div>
    </div>
    <!-- type3 -->
    <div v-show="layout_groups.includes('layout_group3')" class="flex">
      <div class="fx1 flex_center">
        <label class="layout-option-warpper">
          <img :src="layout5_img" />
          <br />
          <input
            type="radio"
            name="layout"
            v-model="layout"
            value="layout5"
            class="layout-option"
          />
        </label>
      </div>
      <div class="fx1 flex_center">
        <label class="layout-option-warpper">
          <img :src="layout6_img" />
          <br />
          <input
            type="radio"
            name="layout"
            v-model="layout"
            value="layout6"
            class="layout-option"
          />
        </label>
      </div>
    </div>
    <hr />
    <!-- prev next -->
    <div class="flex mt10">
      <div class="fx1 flex flex_center">
        <a class="btn btn__bg" href="#" @click.prevent="cancel">上一步</a>&nbsp;&nbsp;
        <a href="#" @click="setStepAndGo" class="btn btn__bg">下一步</a>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: [
    "dom_id",
    "layout_groups",
    "layout0_img",
    "layout1_img",
    "layout2_img",
    "layout3_img",
    "layout4_img",
    "layout5_img",
    "layout6_img",
  ],
  data() {
    return {
      layout: this.getDefaultLayout(),
    };
  },
  methods: {
    getDefaultLayout() {
      const { layout_groups } = this;
      if (layout_groups.includes("layout_group0")) {
        return "layout0";
      }
      if (layout_groups.includes("layout_group1")) {
        return "layout1";
      }
      if (layout_groups.includes("layout_group2")) {
        return "layout3";
      }
      if (layout_groups.includes("layout_group3")) {
        return "layout5";
      }
    },
    setStepAndGo() {
      const { layout } = this;
      if (layout) {
        // 將資料轉到wiward_steps
        this.$emit("arrangeData", layout, 1);
        // 跳到下一步
        this.$emit("goTo", 1);
      } else {
        swal.fire("請選擇版型！");
      }
    },
    cancel() {
      $("#" + this.dom_id).modal("hide");
      const key = this.dom_id.split("_")[1];
      if (key) {
        $("#new-step-modal_" + key).modal("show");
        // 避免body.modal-open加上後又被移除
        setTimeout(() => {
          $("body").addClass("modal-open");
        }, 600);
      } else {
        $("#new-step-modal").modal("show");
        setTimeout(() => {
          $("body").addClass("modal-open");
        }, 600);
      }
    },
  },
};
</script>
