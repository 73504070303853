<template>
  <ul>
    <li>
      <span>小計</span>
      <span><strong>$ {{cart_amount}}</strong></span>
    </li>
    <li>
      <span>運費</span>
      <span><strong>$ {{freight}}</strong></span>
    </li>
    <li>
      <span>總計</span>
      <span><strong>$ {{cart_amount + freight}}</strong></span>
    </li>
  </ul>
</template>

<script>
export default {
  computed: {
    cart_amount: function() {
      return this.$store.state.cart_amount;
    },
    freight: function() {
      return this.$store.state.freight
    }
  }
}
</script>